jQuery(document).ready(function ($) {
  /**
   * Screen Saver
   */
  var screenSaverDelay = 5 * 60 * 1000;
  var displayScreenSaver = function () {
    $('#screen-saver').css('display', 'block');
    var video = $('#screen-saver').find('video').get(0);
    video.play();
  };
  var screenSaverTimer = setTimeout(displayScreenSaver, screenSaverDelay);
  var resetScreenSaverTimer = function () {
    var video = $('#screen-saver').find('video').get(0);
    video.pause();
    video.currentTime = 0;
    clearTimeout(screenSaverTimer);
    screenSaverTimer = setTimeout(displayScreenSaver, screenSaverDelay);
  };
  $(window).click(resetScreenSaverTimer);
  $('#screen-saver').on('click', function () {
    $('.page').removeClass('current');
    if (!$('#menu').hasClass('opened')) {
      $('#menu-btn').click();
    }
    $('#screen-saver').css('display', 'none');
    var video = $('#screen-saver').find('video').get(0);
    video.pause();
    // window.location.reload();
  });
  
  
  // Vidéo en home
    $('#video-overlay').on('click', function () {
        $('.page').removeClass('current');
        if (!$('#menu').hasClass('opened')) {
          $('#menu-btn').click();
        }
        $('#video-overlay').css('display', 'none');
        var video = $('#video-overlay').find('video').get(0);
        video.pause();
        video.currentTime = 0;
    });
    $('#menu #video').on('click', function (e) {
        if (e) e.stopPropagation();
        $('#video-overlay').css('display', 'block');
        var video = $('#video-overlay').find('video').get(0);
        video.volume = 1;
        video.play();
    });
  

  /**
   * Sounds
   */
  var Sound = {
    sounds: {
      page: new Audio('assets/audio/page-open.wav'),
      toggle: new Audio('assets/audio/on.mp3'),
      untoggle: new Audio('assets/audio/off.mp3'),
      woosh: new Audio('assets/audio/woosh.wav'),
      unwoosh: new Audio('assets/audio/unwoosh.wav')
    },
    play: function (ref) {
      var sound = this.sounds[ref];
      sound.pause();
      sound.currentTime = 0;
      sound.play();
    }
  };

  // Ouverture menu
  $('#menu-btn').on('click', function (e) {
    if (e) e.preventDefault();
    resetScreenSaverTimer();
    var $menu = $('#menu');
    Sound.play('woosh');
    $(this).addClass('opened').one('transitionend', function (e) {
      $menu.css('display', 'block');
      setTimeout(function () {
        $menu.addClass('opened');
      }, 100);
    });
  });

  // Liens menu
  $('#menu button').on('click', function (e) {
    if (e) e.stopPropagation();
    resetScreenSaverTimer();
    $('.page').removeClass('current');
    var requestedPage = $(this).attr('data-page');
    $('#page-' + requestedPage).addClass('current');
    const pagesWithourOverlay = ['technique', 'entreprises'];
    if(pagesWithourOverlay.includes(requestedPage)){
      $('#container').addClass('overlay-active');
    } else {
      $('#container').removeClass('overlay-active');
    }
    Sound.play('unwoosh');
    $('#menu').removeClass('opened').css('display', 'none');
    $('#menu-btn').removeClass('opened');
  });

  // Page ouverte par défaut
  $('#menu button[data-page="techniwue"]').click();

  // Transports
  $('#page-acces input').each(function (i, tgl) {
    var $tgl = $(tgl),
      id = $tgl.attr('data-layer'),
      $layer = $('#page-acces .layer[data-layer="' + id + '"]');
    $tgl.on('change', function (e) {
      if ($tgl.is(':checked')) {
        if (e.originalEvent) {
          Sound.play('toggle');
        }
        $layer.removeClass('hidden');
      } else {
        if (e.originalEvent) {
          Sound.play('untoggle');
        }
        $layer.addClass('hidden');
      }
      resetScreenSaverTimer();
    });
    $tgl.change();
  });
  
  // Quartier (ancien services)
  $('#page-services input').each(function (i, tgl) {
    var $tgl = $(tgl),
      id = $tgl.attr('data-layer'),
      $layer = $('#page-services .layer[data-layer="' + id + '"]');
    $tgl.on('change', function (e) {
      if ($tgl.is(':checked')) {
        if (e.originalEvent) {
          Sound.play('toggle');
        }
        $layer.removeClass('hidden');
      } else {
        if (e.originalEvent) {
          Sound.play('untoggle');
        }
        $layer.addClass('hidden');
      }
      resetScreenSaverTimer();
    });
    $tgl.change();
  });

  /* Services */
  $('#page-services .point').each(function (i, point) {
    var $point = $(point),
      $iframe = $('#services-iframe');
    $point.on('click', function (e) {
      if (e) e.preventDefault();
      if (!$point.hasClass('current')) {
        $iframe.attr('src', 'https://my.matterport.com/show/?brand=1&play=1&qs=1&help=0&vrcoll=0&nozoom=1&m='+$point.attr('data-url'));
        $('#page-services .point').removeClass('current');
        $point.addClass('current');
        Sound.play('toggle');
      }
      resetScreenSaverTimer();
    });
  });
  
  // Surfaces
  $('#page-surfaces .point').each(function (i, point) {
    var $point = $(point),
      $card = $('#page-surfaces .card[data-card="' + $point.attr('data-card') + '"]'),
      hasCard = $card.length > 0;
    $point.on('click', function (e) {
      if (e) e.preventDefault();
      if ($point.hasClass('current')) {
        $point.removeClass('current');
        if (hasCard) {
          $card.removeClass('current');
          $('.default-card').css('display', 'block');
        }
        Sound.play('untoggle');
      } else {
        $('.default-card').css('display', 'none');
        $('#page-surfaces .point, #page-surfaces .card').removeClass('current');
        $point.addClass('current');
        if (hasCard) $card.addClass('current');
        Sound.play('toggle');
      }
      resetScreenSaverTimer();
    });
  });
  
  // Technicité
  $('#page-performance input').each(function (i, tgl) {
    var $tgl = $(tgl),
      $layer = $('#page-performance .legendes');
    $tgl.on('change', function (e) {
      if ($tgl.is(':checked')) {
        if (e.originalEvent) {
          Sound.play('toggle');
        }
        $layer.addClass('on');
      } else {
        if (e.originalEvent) {
          Sound.play('untoggle');
        }
        $layer.removeClass('on');
      }
      resetScreenSaverTimer();
    });
    $tgl.change();
  });


  // Simulations
  var $simulationsSlider = $('#page-simulations .slider');
  $simulationsSlider.microslideshow({
    autoplay: false
  });
  $('#page-simulations .btn-left').on('click', function (e) {
    if (e) e.preventDefault();
    $simulationsSlider.microslideshow('pause');
    $simulationsSlider.microslideshow('prev');
  });
  $('#page-simulations .btn-right').on('click', function (e) {
    if (e) e.preventDefault();
    $simulationsSlider.microslideshow('pause');
    $simulationsSlider.microslideshow('next');
  });
  var $simulationsCards = $('#page-simulations .page-quad-inner');
  $simulationsCards.each(function (i, card) {
    var $card = $(card);
    $card.find('.overlay').on('click', function (e) {
      if (e) e.stopPropagation();
      resetScreenSaverTimer();
      
      if (!$card.hasClass('opened')) {
        Sound.play('woosh');
        $card.addClass('opened');
        $('#container').addClass('overlay-active');
        $('#menu-btn').css('display', 'none');
        if (i === 6) {
          $simulationsSlider.microslideshow('play');
        }
      }
    });
    $card.find('.overlay-closer').on('click', function (e) {
      if (e) e.stopPropagation();
      resetScreenSaverTimer();
      if ($card.hasClass('opened')) {
        if (i === 6) {
          $simulationsSlider.microslideshow('pause');
        }
        Sound.play('unwoosh');
        $('#container').removeClass('overlay-active');
        $('#menu-btn').css('display', 'block');
        $card.removeClass('opened');
        
      }
    });
  });

  // Entreprises
  $('#page-entreprises input').each(function (i, tgl) {
    var $tgl = $(tgl),
      $list = $('#liste-entreprises'),
      $layer = $('#page-entreprises .legendes');
    $tgl.on('change', function (e) {
      if ($tgl.is(':checked')) {
        if (e.originalEvent) {
          Sound.play('toggle');
        }
        $layer.addClass('on');
        $list.addClass('liste-chiffres');
      } else {
        if (e.originalEvent) {
          Sound.play('untoggle');
        }
        $layer.removeClass('on');
        $list.removeClass('liste-chiffres')
      }
      resetScreenSaverTimer();
    });
    $tgl.change();
  });
  

  // Technique
  const $techniqueSlider = $('#page-technique .slider');
  $techniqueSlider.microslideshow({
    autoplay: false
  });
  $('#page-technique .btn-left').on('click', function (e) {
    if (e) e.preventDefault();
    $techniqueSlider.microslideshow('pause');
    $techniqueSlider.microslideshow('prev');
    Sound.play('untoggle');
  });
  $('#page-technique .btn-right').on('click', function (e) {
    if (e) e.preventDefault();
    $techniqueSlider.microslideshow('pause');
    $techniqueSlider.microslideshow('next');
    Sound.play('toggle');
  });


});
  